import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SaveButton,
  TextField,
  useAutocomplete,
} from "@pankod/refine-mui";
import { Controller, useForm } from "@pankod/refine-react-hook-form";
import { IMaterial } from "interfaces";
import { IEnabledModalProps } from "../interfaces";
import { Add } from "@mui/icons-material";

export const EnabledProductModal: React.FC<IEnabledModalProps> = ({
  visible,
  close,
  title,
  data,
  setData,
  canAdd = false,
  setCanAdd,
}) => {
  const {
    saveButtonProps,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "null",
    },
  });

  const { autocompleteProps: productsAutocompleteProps } =
    useAutocomplete<IMaterial>({
      resource: "raw-materials",
      onSearch: (searchText) => [
        {
          operator: "or",
          value: [
            {
              field: "code",
              operator: "contains",
              value: searchText,
            },
            {
              field: "name",
              operator: "contains",
              value: searchText,
            },
          ],
        },
      ],
    });

  const cleanInputs = () => {
    setValue("product", null);
    setValue("quantity_pieces", "0");
    setValue("weight_pieces", "0");
    setCanAdd?.(false);
  };

  const onSubmit = (submitData: any, another: boolean) => {
    let newData = {
      ...submitData,
      action: "create",
    };
    if (data?.id) {
      if (!canAdd) {
        newData = {
          ...newData,
          id: data?.id,
          action: "update",
        };
      } else {
        newData = {
          ...newData,
          id: data?.id,
          action: "update",
          quantity_pieces:
            parseFloat(getValues("quantity_pieces")) +
            parseFloat(data?.quantity_pieces),
          weight_pieces:
            parseFloat(getValues("weight_pieces")) +
            parseFloat(data?.weight_pieces),
        };
      }
    }
    setData(newData, another);
    cleanInputs();
  };

  useEffect(() => {
    setValue("quantity_pieces", "0");
    setValue("weight_pieces", "0");
    if (data?.id) {
      setValue("product", data?.product);
      if (!canAdd) {
        setValue("quantity_pieces", data?.quantity_pieces);
        setValue("weight_pieces", data?.weight_pieces);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog
      open={visible}
      onClose={() => {
        cleanInputs();
        close();
      }}
      PaperProps={{ sx: { minWidth: 500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            control={control}
            name="product"
            rules={{ required: "El campo producto sobrante es obligatorio." }}
            defaultValue={null as any}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...productsAutocompleteProps}
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => {
                    const label = productsAutocompleteProps?.options?.find(
                      (p) => p?.id?.toString() === item?.id?.toString()
                    );

                    if (!label?.name || !label?.code) return "";
                    return `${label?.code} - ${label?.name}`;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined ||
                      option.id.toString() === value.id.toString()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Producto"
                      margin="normal"
                      variant="outlined"
                      error={!!errors.product}
                      helperText={errors.product?.message?.toString()}
                      required
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            {...register("quantity_pieces", {
              required: "El campo cantidad piezas es obligatorio.",
            })}
            onFocus={() => {
              if (watch("quantity_pieces") === "0")
                setValue("quantity_pieces", "");
            }}
            error={!!errors?.quantity_pieces}
            helperText={errors.quantity_pieces?.message?.toString()}
            margin="normal"
            fullWidth
            id="quantity_pieces"
            label="Cantidad Piezas"
            name="quantity_pieces"
            type={"number"}
            required
            defaultValue={""}
          />
          {canAdd && (
            <div>
              {data?.quantity_pieces} /{" "}
              {+data?.quantity_pieces + +watch("quantity_pieces")}
            </div>
          )}
          <TextField
            {...register("weight_pieces", {
              required: "El campo peso piezas es obligatorio.",
            })}
            onFocus={() => {
              if (watch("weight_pieces") === "0") setValue("weight_pieces", "");
            }}
            error={!!errors?.weight_pieces}
            helperText={errors.weight_pieces?.message?.toString()}
            margin="normal"
            fullWidth
            id="weight_pieces"
            label="Peso Piezas"
            name="weight_pieces"
            type={"number"}
            required
            defaultValue={""}
          />
          {canAdd && (
            <div>
              {data?.weight_pieces} /{" "}
              {+data?.weight_pieces + +watch("weight_pieces")}
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancelar</Button>
        {!data?.id && (
          <Button
            variant="outlined"
            onClick={(e: React.BaseSyntheticEvent) => {
              e.preventDefault();
              handleSubmit(
                (data) => onSubmit(data, true),
                () => false
              )(e);
            }}
            startIcon={<Add />}
          >
            Agregar otro
          </Button>
        )}
        <SaveButton
          {...saveButtonProps}
          onClick={(e: React.BaseSyntheticEvent) => {
            e.preventDefault();
            handleSubmit(
              (data) => onSubmit(data, false),
              () => false
            )(e);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
