import React from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  esES,
  Grid,
} from "@pankod/refine-mui";
import { IOrder, IProduct } from "interfaces";
import { TextField } from "@pankod/refine-mui";
import { getTodayDate } from "functions";

export const FinishedPiecesList: React.FC = () => {
  const [date, setDate] = React.useState<any>(getTodayDate());
  const { dataGridProps } = useDataGrid<IProduct>({
    resource: `finished-pieces/report/${date}`,
  });

  const columns = React.useMemo<GridColumns<IOrder>>(
    () => [
      {
        field: "employeeName",
        headerName: "Nombre",
        flex: 1,
        minWidth: 300,
      },
      {
        field: "habilitado",
        headerName: "Hab.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      // {
      //   field: "Calado",
      //   headerName: "Cal.",
      //   flex: 1,
      //   headerAlign: "center",
      //   align: "center",
      // },
      {
        field: "soldado",
        headerName: "Sol.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "pulido",
        headerName: "Pul.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "grabado",
        headerName: "Gra.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "detallado",
        headerName: "Det.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "montado",
        headerName: "Mon.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "lustre_2",
        headerName: "Lus2.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "pintado",
        headerName: "Pin.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "envuelto",
        headerName: "Env.",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "total_qty",
        headerName: "T. Piezas",
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "total_weight",
        headerName: "T. Peso",
        flex: 1,
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        renderCell: (params) => {
          return `${params.value.toFixed(2)} gr`;
        },
      },
    ],
    []
  );

  return (
    <List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            margin="normal"
            type={"date"}
            id="date"
            label="Fecha"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Grid>
      </Grid>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        getRowHeight={() => "auto"}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
        hideFooter
      />
      {/* <ModalRender params={currentModal} /> */}
    </List>
  );
};
