import React, { useEffect } from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  // EditButton,
  // DeleteButton,
  List,
  Stack,
  esES,
  DateField,
  Grid,
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  InputAdornment,
} from "@pankod/refine-mui";
import {
  IEnabled,
  IFinishedPieces,
  IOrder,
  IOrderFilterVariables,
  IProduct,
  Nullable,
} from "interfaces";
import Actions, { ColorTypes } from "../components/actions";
import {
  AppsOutlined,
  BuildOutlined,
  InventoryOutlined,
  CancelOutlined,
  SearchOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
// import { FinishedPiecesModal } from "../pages/enabled/modals/finished_pieces";
import {
  CrudFilters,
  HttpError,
  getDefaultFilter,
  useCustomMutation,
  useModal,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { API_URL } from "../../../constants";
import { FinishedPiecesModal } from "components/Modals/finished_pieces";
import {
  finishedPiecesColumns,
  finishedPiecesSimpleColumns,
} from "../pages/enabled/grid_columns";
import { EditButton as EditButtonCustom } from "components/buttons/edit";
import { ClientMaterialsModal } from "components/Modals/client_materials";
import { clientMaterialColumns, clientProductColumns } from "../grid_columns";
import { ClientProductsModal } from "components/Modals/client_products";
import { FinishedPiecesSimpleModal } from "components/Modals/finished_pieces/simple";
import { OrderCancelModal } from "components/Modals/order_cancel";
import { OrderDetailsModal } from "components/Modals/order_details";
import { Link } from "@pankod/refine-react-router-v6";
import { useForm } from "@pankod/refine-react-hook-form";

export interface IModalRenderProps {
  params?: any;
}

export const ModalRender: React.FC<IModalRenderProps> = ({ params }) => {
  if (!params) return null;
  const { visible, modalComponent: ModalComponent } = params;
  return <>{visible && <ModalComponent {...params} />}</>;
};

export const OrderOpenList: React.FC = () => {
  const { dataGridProps, tableQueryResult, filters, setFilters } = useDataGrid<
    IProduct,
    HttpError,
    Nullable<IOrderFilterVariables>
  >({
    resource: "orders",
    permanentFilter: [
      {
        field: "status",
        value: ["Terminada", "Cancelada"],
        operator: "notIn" as any,
      },
    ],
    metaData: {
      populate: [
        "client",
        "catalog",
        "enabled.employee",
        "pasted.employee",
        "fretwork.employee",
        "welded.employee",
        "polish.employee",
        "engrave.employee",
        "detail.employee",
        "assemble.employee",
        "luster.employee",
        "painted.employee",
        "wrapped.employee",
        "sent",
        "order_file",
      ],
    },
    queryOptions: {
      cacheTime: 0,
    },
    onSearch: (params) => {
      const { q } = params;
      const filters: CrudFilters = [];

      filters.push({
        operator: "or",
        value: [
          {
            field: "order",
            operator: "contains",
            value: q,
          },
          {
            field: "catalog.sku",
            operator: "contains",
            value: q,
          },
          {
            field: "client.name",
            operator: "contains",
            value: q,
          },
          {
            field: "final_client",
            operator: "contains",
            value: q,
          },
        ],
      });

      return filters;
    },
  });

  const translate = useTranslate();

  const { show, close, visible } = useModal();

  const [currentModal, setCurrentModal] = React.useState<any>(null);
  const { mutate: finishedPiecesMutate } = useCustomMutation<IFinishedPieces>();
  const { mutate: enabledMutate } = useUpdate<IEnabled>();

  const setFinishedPieces = (
    data: any,
    newData: any,
    process: string,
    resource: string
  ) => {
    const qty = +newData?.qty.toFixed(0);
    const realWeight = +newData?.real_weight.toFixed(2);
    const decrease = +data?.decrease?.toFixed(2);

    finishedPiecesMutate({
      url: `${API_URL}/api/finished-pieces`,
      method: "post",
      values: {
        data: {
          date: newData?.date,
          employee: newData?.employee,
          qty: qty,
          real_weight: realWeight,
          process: process,
          order: data?.order,
        },
      },
    });

    const newFinishedPieces = +newData?.total_qty.toFixed(0);
    const newRealWeight = +newData?.total_real_weight.toFixed(2);
    const newDecrease = decrease - realWeight;

    enabledMutate(
      {
        resource: resource,
        id: data?.id as string,
        values: {
          finished_pieces: newFinishedPieces,
          real_weight: newRealWeight,
          weight: newRealWeight,
          decrease: +newDecrease.toFixed(2),
        },
        successNotification: () => {
          return {
            description: translate("notifications.success"),
            message: translate(`${resource}.edit.finished_pieces.success`),
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          tableQueryResult?.refetch();
        },
      }
    );
    close();
  };

  const setFinishedPiecesSimple = (
    data: any,
    newData: any,
    process: string,
    resource: string
  ) => {
    const qty = +newData?.qty.toFixed(0);

    finishedPiecesMutate({
      url: `${API_URL}/api/finished-pieces`,
      method: "post",
      values: {
        data: {
          date: newData?.date,
          employee: newData?.employee,
          qty: qty,
          process: process,
          order: data?.order,
        },
      },
    });

    const newFinishedPieces = +newData?.total_qty.toFixed(0);

    enabledMutate(
      {
        resource: resource,
        id: data?.id as string,
        values: {
          finished_pieces: newFinishedPieces,
        },
        successNotification: () => {
          return {
            description: translate("notifications.success"),
            message: translate(`${resource}.edit.finished_pieces.success`),
            type: "success",
          };
        },
      },
      {
        onSuccess: () => {
          tableQueryResult?.refetch();
        },
      }
    );
    close();
  };

  const handleModal = ({
    modal,
    title,
    data = {},
    order,
  }: {
    modal: string;
    title: string;
    data?: any;
    order: any;
  }) => {
    show();
    const params = {
      close: close,
      canAdd: true,
    };

    let currentData: any;

    switch (modal) {
      case "EnabledFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Habilitado", "enableds");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Habilitado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;
      case "WeldedFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Soldado", "weldeds");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Soldado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "PolishedFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Pulido", "polishes");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Pulido",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "EngravedFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Grabado", "engraves");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Grabado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "AssembledFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Montado", "assembles");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Montado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "DetailsFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Detallado", "details");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Detallado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "LusterFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Lustre 2", "lusters");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Lustre 2",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "PaintedFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesSimpleModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPiecesSimple(
              currentData,
              newData,
              "Pintado",
              "painteds"
            );
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Pintado",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesSimpleColumns,
          },
        });
        break;

      case "WrappedFinishedPiecesModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: FinishedPiecesModal,
          title: title,
          data: currentData,
          setData: (newData: any) => {
            setFinishedPieces(currentData, newData, "Envuelto", "wrappeds");
          },
          history: {
            config: {
              filters: [
                {
                  field: "process",
                  operator: "eq",
                  value: "Envuelto",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["employee"],
            },
            columns: finishedPiecesColumns,
          },
        });
        break;

      case "ClientMaterialModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: ClientMaterialsModal,
          title: title,
          data: currentData,
          history: {
            config: {
              filters: [
                {
                  field: "type",
                  operator: "eq",
                  value: "Entrada",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["order", "raw_material"],
            },
            columns: clientMaterialColumns,
          },
        });
        break;

      case "ClientProductsModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: ClientProductsModal,
          title: title,
          data: currentData,
          history: {
            config: {
              filters: [
                {
                  field: "type",
                  operator: "eq",
                  value: "Entrada",
                },
                {
                  field: "order",
                  operator: "" as any,
                  value: order,
                },
              ],
            },
            metaData: {
              populate: ["order", "product"],
            },
            columns: clientProductColumns,
          },
        });
        break;

      case "OrderCancelModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: OrderCancelModal,
          title: title,
          data: currentData,
        });
        break;

      case "OrderDetailsModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: OrderDetailsModal,
          title: title,
          data: currentData,
        });
        break;

      default:
        break;
    }
  };

  const columns = React.useMemo<GridColumns<IOrder>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        flex: 1,
        headerClassName: "stycky-header",
        minWidth: 130,
        renderCell: function render({ row }) {
          return (
            <DateField
              value={row.createdAt}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      { field: "type", headerName: "Tipo de Orden", minWidth: 130, flex: 1 },
      {
        field: "order",
        headerName: "# de Orden",
        minWidth: 130,
        flex: 1,
        renderCell: function render({ row }) {
          row = {
            ...row,
            norder: row.order,
          };

          return (
            <Link
              to="#"
              onClick={() => {
                handleModal({
                  modal: "OrderDetailsModal",
                  title: "Detalles de la orden",
                  data: row,
                  order: row?.id,
                });
              }}
            >
              {row.order}
            </Link>
          );
        },
      },
      {
        field: "order_file",
        headerName: "Pdf",
        minWidth: 50,
        flex: 1,
        align: "center",
        renderCell: (params) => {
          if (!params?.row?.order_file?.url) {
            return null;
          }

          return (
            <a
              href={`${API_URL}${params.row.order_file.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <PictureAsPdf fontSize="medium" style={{ color: "black" }} />
            </a>
          );
        },
      },
      {
        field: "sku",
        headerName: "SKU",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
          return params.row.catalog?.sku;
        },
      },
      {
        field: "client",
        headerName: "Cliente",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
          return params.row.client.name;
        },
      },
      {
        field: "final_client",
        headerName: "Cliente final",
        minWidth: 130,
        flex: 1,
      },
      {
        field: "delivery_date",
        headerName: "Fecha de entrega",
        minWidth: 130,
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.delivery_date) return "Sin fecha";
          return (
            <DateField
              value={row.delivery_date}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      {
        field: "delivery_date_diff",
        headerName: "Dias restantes",
        minWidth: 130,
        align: "center",
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.delivery_date) return "Sin fecha";
          const date = new Date(row.delivery_date);
          const today = new Date();
          const diffTime = date.getTime() - today.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays;
        },
      },
      // {
      //   field: "pdf",
      //   headerName: "PDF",
      //   flex: 1,
      //   renderCell: function render({ row }) {
      //     return "";
      //   },
      // },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "enabled",
        headerName: "Hab.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.enabled?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "preenabled",
              color: "info" as ColorTypes,
              title: "Pre Habilitar",
              size: 12,
            });
          } else if (row?.enabled?.id) {
            params.push({
              type: "modal",
              id: row?.enabled?.id,
              resourceNameOrRouteName: "enabled",
              icon: BuildOutlined,
              title: "Piezas terminadas - Habilitado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "EnabledFinishedPiecesModal",
                  title: "Piezas terminadas - Habilitado",
                  data: {
                    id: row?.enabled?.id,
                    currentEmployee: row?.enabled?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.enabled?.finished_pieces,
                    realWeight: row?.enabled?.real_weight,
                    decrease: row?.enabled?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.enabled?.id,
              resourceNameOrRouteName: "enabled",
              title: "Editar Habilitado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "pasted",
        headerName: "Peg.",
        flex: 1,
        headerAlign: "center",
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.pasted?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "pasted",
              color: "info" as ColorTypes,
              title: "Pegar",
              size: 12,
            });
          } else if (row?.pasted?.id) {
            params.push({
              type: "edit",
              id: row?.pasted?.id,
              resourceNameOrRouteName: "pasted",
              title: "Editar Pegado",
              size: 12,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "fretwork",
        headerName: "Cal.",
        flex: 1,
        headerAlign: "center",
        renderCell: function render({ row }) {
          let params = [];
          if (row?.pasted?.id) {
            if (!row?.fretwork?.id) {
              params.push({
                type: "create",
                id: row.id,
                resourceNameOrRouteName: "fretwork",
                color: "info" as ColorTypes,
                title: "Calar",
                size: 12,
              });
            } else if (row?.fretwork?.id) {
              params.push({
                type: "edit",
                id: row?.fretwork?.id,
                resourceNameOrRouteName: "fretwork",
                title: "Editar Calado",
                size: 12,
                iconProps: {
                  color: "white",
                },
                buttonProps: {
                  variant: "contained",
                  size: "small",
                },
              });
            }
          }
          return (
            params.length > 0 && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "welded",
        headerName: "Sol.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.welded?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "prewelded",
              color: "info" as ColorTypes,
              title: "Pre Soldar",
              size: 12,
            });
          } else if (row?.welded?.id) {
            params.push({
              type: "modal",
              id: row?.welded?.id,
              resourceNameOrRouteName: "welded",
              icon: BuildOutlined,
              title: "Piezas terminadas - Soldado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "WeldedFinishedPiecesModal",
                  title: "Piezas terminadas - Soldado",
                  data: {
                    id: row?.welded?.id,
                    currentEmployee: row?.welded?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.welded?.finished_pieces,
                    realWeight: row?.welded?.real_weight,
                    decrease: row?.welded?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.welded?.id,
              resourceNameOrRouteName: "welded",
              title: "Editar Soldado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "polishes",
        headerName: "Pul.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.polish?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "polishes",
              color: "info" as ColorTypes,
              title: "Pulir",
              size: 12,
            });
          } else if (row?.polish?.id) {
            params.push({
              type: "modal",
              id: row?.polish?.id,
              resourceNameOrRouteName: "polishes",
              icon: BuildOutlined,
              title: "Piezas terminadas - Pulido",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "PolishedFinishedPiecesModal",
                  title: "Piezas terminadas - Pulido",
                  data: {
                    id: row?.polish?.id,
                    currentEmployee: row?.polish?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.polish?.finished_pieces,
                    realWeight: row?.polish?.real_weight,
                    decrease: row?.polish?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.polish?.id,
              resourceNameOrRouteName: "polishes",
              title: "Editar Pulido",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "engraves",
        headerName: "Gra.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.engrave?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "engraves",
              color: "info" as ColorTypes,
              title: "Grabar",
              size: 12,
            });
          } else if (row?.engrave?.id) {
            params.push({
              type: "modal",
              id: row?.engrave?.id,
              resourceNameOrRouteName: "engraves",
              icon: BuildOutlined,
              title: "Piezas terminadas - Grabado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "EngravedFinishedPiecesModal",
                  title: "Piezas terminadas - Grabado",
                  data: {
                    id: row?.engrave?.id,
                    currentEmployee: row?.engrave?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.engrave?.finished_pieces,
                    realWeight: row?.engrave?.real_weight,
                    decrease: row?.engrave?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.engrave?.id,
              resourceNameOrRouteName: "engraves",
              title: "Editar Grabado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "details",
        headerName: "Det.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.detail?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "details",
              color: "info" as ColorTypes,
              title: "Detallar",
              size: 12,
            });
          } else if (row?.detail?.id) {
            params.push({
              type: "modal",
              id: row?.detail?.id,
              resourceNameOrRouteName: "details",
              icon: BuildOutlined,
              title: "Piezas terminadas - Detallado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "DetailsFinishedPiecesModal",
                  title: "Piezas terminadas - Detallado",
                  data: {
                    id: row?.detail?.id,
                    currentEmployee: row?.detail?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.detail?.finished_pieces,
                    realWeight: row?.detail?.real_weight,
                    decrease: row?.detail?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.detail?.id,
              resourceNameOrRouteName: "details",
              title: "Editar Detallado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "assembles",
        headerName: "Mon.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.assemble?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "assembles",
              color: "info" as ColorTypes,
              title: "Montar",
              size: 12,
            });
          } else if (row?.assemble?.id) {
            params.push({
              type: "modal",
              id: row?.assemble?.id,
              resourceNameOrRouteName: "assembles",
              icon: BuildOutlined,
              title: "Piezas terminadas - Montado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "AssembledFinishedPiecesModal",
                  title: "Piezas terminadas - Montado",
                  data: {
                    id: row?.assemble?.id,
                    currentEmployee: row?.assemble?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.assemble?.finished_pieces,
                    realWeight: row?.assemble?.real_weight,
                    decrease: row?.assemble?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.assemble?.id,
              resourceNameOrRouteName: "assembles",
              title: "Editar Montado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "lusters",
        headerName: "Lus2.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.luster?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "lusters",
              color: "info" as ColorTypes,
              title: "Lustre 2",
              size: 12,
            });
          } else if (row?.luster?.id) {
            params.push({
              type: "modal",
              id: row?.luster?.id,
              resourceNameOrRouteName: "polishes",
              icon: BuildOutlined,
              title: "Piezas terminadas - Lustre 2",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "LusterFinishedPiecesModal",
                  title: "Piezas terminadas - Lustre 2",
                  data: {
                    id: row?.luster?.id,
                    currentEmployee: row?.luster?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.luster?.finished_pieces,
                    realWeight: row?.luster?.real_weight,
                    decrease: row?.luster?.decrease,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.luster?.id,
              resourceNameOrRouteName: "lusters",
              title: "Editar Lustre 2",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "painteds",
        headerName: "Pin.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.painted?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "painteds",
              color: "info" as ColorTypes,
              title: "Pintado",
              size: 12,
            });
          } else if (row?.painted?.id) {
            params.push({
              type: "modal",
              id: row?.painted?.id,
              resourceNameOrRouteName: "painteds",
              icon: BuildOutlined,
              title: "Piezas terminadas - Pintado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "PaintedFinishedPiecesModal",
                  title: "Piezas terminadas - Pintado",
                  data: {
                    id: row?.painted?.id,
                    currentEmployee: row?.painted?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.painted?.finished_pieces,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.painted?.id,
              resourceNameOrRouteName: "painteds",
              title: "Editar Pintado",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "wrappeds",
        headerName: "Env.",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.wrapped?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "wrappeds",
              color: "info" as ColorTypes,
              title: "Envuelto",
              size: 12,
            });
          } else if (row?.wrapped?.id) {
            params.push({
              type: "modal",
              id: row?.wrapped?.id,
              resourceNameOrRouteName: "wrappeds",
              icon: BuildOutlined,
              title: "Piezas terminadas - Envuelto",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
                color: "secondary",
              },
              onClick: () => {
                handleModal({
                  modal: "WrappedFinishedPiecesModal",
                  title: "Piezas terminadas - Envuelto",
                  data: {
                    id: row?.wrapped?.id,
                    currentEmployee: row?.wrapped?.employee,
                    orderQty: row?.total_pieces,
                    currentQty: row?.wrapped?.finished_pieces,
                    realWeight: row?.wrapped?.weight,
                  },
                  order: row?.id,
                });
              },
            });
            params.push({
              type: "edit",
              id: row?.wrapped?.id,
              resourceNameOrRouteName: "wrappeds",
              title: "Editar Envuelto",
              size: 6,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        field: "sents",
        headerName: "Enviado",
        flex: 1,
        headerAlign: "center",
        minWidth: 80,
        renderCell: function render({ row }) {
          let params = [];
          if (!row?.sent?.id) {
            params.push({
              type: "create",
              id: row.id,
              resourceNameOrRouteName: "sents",
              color: "info" as ColorTypes,
              title: "Enviado",
              size: 12,
            });
          } else if (row?.sent?.id) {
            params.push({
              type: "edit",
              id: row?.sent?.id,
              resourceNameOrRouteName: "sents",
              title: "Editar Enviado",
              size: 12,
              iconProps: {
                color: "white",
              },
              buttonProps: {
                variant: "contained",
                size: "small",
              },
            });
          }

          return (
            params.length && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flex: 1,
                }}
              >
                <Actions params={params} />
              </div>
            )
          );
        },
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 180,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          const { row } = params;
          return (
            <Stack direction="row" spacing={1}>
              <EditButtonCustom
                resourceNameOrRouteName={"open"}
                hideText
                title={"Editar orden"}
                recordItemId={row.id}
                buttonProps={
                  {
                    variant: "contained",
                    size: "small",
                  } as any
                }
              />
              <EditButtonCustom
                hideText
                title={"Material de cliente"}
                recordItemId={row.id}
                buttonProps={{
                  variant: "contained",
                  size: "small",
                }}
                Icon={InventoryOutlined}
                style={{ backgroundColor: "#3f51b5", color: "white" }}
                onClick={() => {
                  handleModal({
                    modal: "ClientMaterialModal",
                    title: "Material de cliente",
                    order: row?.id,
                  });
                }}
              />
              <EditButtonCustom
                hideText
                title={"Productos de cliente"}
                recordItemId={row.id}
                buttonProps={{
                  variant: "contained",
                  size: "small",
                }}
                Icon={AppsOutlined}
                style={{ backgroundColor: "#00106a", color: "white" }}
                onClick={() => {
                  handleModal({
                    modal: "ClientProductsModal",
                    title: "Productos de cliente",
                    order: row?.id,
                  });
                }}
              />
              {/* <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              /> */}
              <EditButtonCustom
                hideText
                title={"Cancelar orden"}
                recordItemId={row.id}
                buttonProps={{
                  variant: "contained",
                  size: "small",
                }}
                Icon={CancelOutlined}
                style={{ backgroundColor: "#c90000", color: "white" }}
                onClick={() => {
                  handleModal({
                    modal: "OrderCancelModal",
                    title: "Cancelar orden",
                    data: {
                      comments: row?.comments,
                    },
                    order: row?.id,
                  });
                }}
              />
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible]
  );

  const { register, handleSubmit, reset } = useForm<
    IOrder,
    HttpError,
    Nullable<IOrderFilterVariables>
  >({
    defaultValues: {
      q: getDefaultFilter("q", filters, "eq"),
    },
  });

  useEffect(() => {
    setCurrentModal({
      ...currentModal,
      visible: visible,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleSearch = (params: any) => {
    const { q } = params;
    const filters: CrudFilters = [];

    filters.push({
      operator: "or",
      value: [
        {
          field: "type",
          operator: "contains",
          value: q,
        },
        {
          field: "order",
          operator: "contains",
          value: q,
        },
        {
          field: "catalog.sku",
          operator: "contains",
          value: q,
        },
        {
          field: "client.name",
          operator: "contains",
          value: q,
        },
        {
          field: "final_client",
          operator: "contains",
          value: q,
        },
        {
          field: "status",
          operator: "contains",
          value: q,
        },
      ],
    });
    setFilters(filters);
  };

  return (
    <List>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
            <CardContent sx={{ pt: 0 }}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
                autoComplete="off"
                onSubmit={handleSubmit(handleSearch)}
              >
                <TextField
                  {...register("q")}
                  id="q"
                  label="Buscar"
                  placeholder="Tipo de Orden, # de Orden, SKU, cliente, cliente final, Status"
                  margin="normal"
                  fullWidth
                  autoFocus
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{
                    height: 40,
                  }}
                >
                  Buscar
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="error"
                  style={{
                    height: 40,
                  }}
                  onClick={() => {
                    setFilters([]);
                    reset();
                  }}
                >
                  Limpiar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DataGrid
            {...dataGridProps}
            autoHeight
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            getRowHeight={() => "auto"}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
          />
        </Grid>
      </Grid>
      <ModalRender params={currentModal} />
    </List>
  );
};
