import { IOrderType } from "interfaces";

// export const API_URL = "http://local.ladyluckjewelry.com.mx:1337";
export const API_URL = "https://api.v2.ladyluckjewelry.com.mx";
// export const API_URL = "https://api.v2.dev.ladyluckjewelry.com.mx";
export const TOKEN_KEY = "strapi-jwt-token";


export const MIME_TYPES_DOCS = {
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
    'application/xml': ['.xml'],
}

export const typesOrder: IOrderType[] = [
    {
        name: "Unitario",
    },
    {
        name: "Múltiple",
    },
    {
        name: "Reparación",
    },
];