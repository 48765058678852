import { AddOutlined, EditOutlined } from "@mui/icons-material";
import { Button, DeleteButton, GridColumns, Stack } from "@pankod/refine-mui";

export const materialColumns: GridColumns = [
  {
    field: "material",
    headerName: "Materia prima",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.material.code + " - " + params.row.material.name;
    },
  },
  {
    field: "delivered_weight",
    headerName: "Peso material",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];

export const materialEditColumns: GridColumns = [
  {
    field: "material",
    headerName: "Materia prima",
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return params.row.material.code + " - " + params.row.material.name;
    },
  },
  {
    field: "delivered_weight",
    headerName: "Peso material",
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    headerName: "Acciones",
    headerAlign: "center",
    field: "actions",
    minWidth: 180,
    align: "center",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <Stack direction="row" spacing={1}>
          <Button>
            <AddOutlined
              color="info"
              fontSize="small"
              onClick={() => row.update(row, true)}
            />
          </Button>
          <Button>
            <EditOutlined
              fontSize="small"
              onClick={() => row.update(row, false)}
            />
          </Button>
          <DeleteButton
            size="small"
            hideText
            onClick={() => row.delete(row.id)}
          />
        </Stack>
      );
    },
  },
];
