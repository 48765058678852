import React, { useEffect } from "react";

import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  esES,
  DateField,
} from "@pankod/refine-mui";
import { useModal } from "@pankod/refine-core";
import { LockOpenOutlined, PictureAsPdf } from "@mui/icons-material";
import { IOrder, IProduct } from "interfaces";
import { EditButton as EditButtonCustom } from "components/buttons/edit";
import { OrderOpenModal } from "components/Modals/order_open";
import { ModalRender } from "../open/list";
import { Link } from "@pankod/refine-react-router-v6";
import { OrderDetailsModal } from "components/Modals/order_details";
import { API_URL } from "../../../constants";

export const OrderFinishedList: React.FC = () => {
  const { dataGridProps } = useDataGrid<IProduct>({
    resource: "orders",
    permanentFilter: [
      { field: "status", value: ["Terminada", "Cancelada"], operator: "in" },
    ],
    metaData: {
      populate: [
        "client",
        "enabled.employee",
        "pasted.employee",
        "fretwork.employee",
        "welded.employee",
        "polish.employee",
        "engrave.employee",
        "detail.employee",
        "assemble.employee",
        "luster.employee",
        "painted.employee",
        "wrapped.employee",
        "sent",
        "order_file",
      ],
    },
    queryOptions: {
      cacheTime: 0,
    },
  });

  const { show, close, visible } = useModal();

  const [currentModal, setCurrentModal] = React.useState<any>(null);

  const handleModal = ({
    modal,
    title,
    data = {},
    order,
  }: {
    modal: string;
    title: string;
    data?: any;
    order: any;
  }) => {
    show();
    const params = {
      close: close,
      canAdd: true,
    };

    let currentData: any;

    switch (modal) {
      case "OrderOpenModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: OrderOpenModal,
          title: title,
          data: currentData,
        });
        break;

      case "OrderDetailsModal":
        currentData = {
          ...data,
          order: order,
        };

        setCurrentModal({
          ...params,
          modalComponent: OrderDetailsModal,
          title: title,
          data: currentData,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setCurrentModal({
      ...currentModal,
      visible: visible,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const columns = React.useMemo<GridColumns<IOrder>>(
    () => [
      {
        field: "createdAt",
        headerName: "Fecha",
        flex: 1,
        headerClassName: "stycky-header",
        minWidth: 130,
        renderCell: function render({ row }) {
          return (
            <DateField
              value={row.createdAt}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      { field: "type", headerName: "Tipo de Orden", minWidth: 130, flex: 1 },
      {
        field: "order",
        headerName: "# de Orden",
        minWidth: 130,
        flex: 1,
        renderCell: function render({ row }) {
          row = {
            ...row,
            norder: row.order,
          };

          return (
            <Link
              to="#"
              onClick={() => {
                handleModal({
                  modal: "OrderDetailsModal",
                  title: "Detalles de la orden",
                  data: row,
                  order: row?.id,
                });
              }}
            >
              {row.order}
            </Link>
          );
        },
      },
      {
        field: "order_file",
        headerName: "Pdf",
        minWidth: 50,
        flex: 1,
        align: "center",
        renderCell: (params) => {
          if (!params?.row?.order_file?.url) {
            return null;
          }

          return (
            <a
              href={`${API_URL}${params.row.order_file.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <PictureAsPdf fontSize="medium" style={{ color: "black" }} />
            </a>
          );
        },
      },
      { field: "sku", headerName: "SKU", minWidth: 130, flex: 1 },
      {
        field: "client",
        headerName: "Cliente",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => {
          return params.row.client.name;
        },
      },
      {
        field: "final_client",
        headerName: "Cliente final",
        minWidth: 130,
        flex: 1,
      },
      {
        field: "delivery_date",
        headerName: "Fecha de entrega",
        minWidth: 130,
        flex: 1,
        renderCell: function render({ row }) {
          if (!row.delivery_date) return "Sin fecha";
          return (
            <DateField
              value={row.delivery_date}
              format={"DD / MMM / YYYY"}
              locales={"es-mx"}
              style={{ textTransform: "uppercase" }}
            />
          );
        },
      },
      // {
      //   field: "pdf",
      //   headerName: "PDF",
      //   flex: 1,
      //   renderCell: function render({ row }) {
      //     return "";
      //   },
      // },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 100,
      },
      {
        headerName: "Acciones",
        headerAlign: "center",
        field: "actions",
        minWidth: 100,
        align: "center",
        flex: 1,
        renderCell: function render(params) {
          const { row } = params;
          return (
            <Stack direction="row" spacing={1}>
              {/* <EditButton size="small" hideText recordItemId={params.row.id} />
              <DeleteButton
                size="small"
                hideText
                recordItemId={params.row.id}
              /> */}
              <EditButtonCustom
                hideText
                title={"Abrir orden"}
                recordItemId={row.id}
                buttonProps={{
                  variant: "contained",
                  size: "small",
                }}
                Icon={LockOpenOutlined}
                style={{ color: "white" }}
                onClick={() => {
                  handleModal({
                    modal: "OrderOpenModal",
                    title: "Abrir orden",
                    data: {
                      comments: row.comments,
                    },
                    order: row?.id,
                  });
                }}
              />
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible]
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        autoHeight
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        getRowHeight={() => "auto"}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
      />
      <ModalRender params={currentModal} />
    </List>
  );
};
